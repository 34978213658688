<template>
  <div class="bg-white">
    <header
      class="sticky-top border border-light-bottom border-light-line border-light-top border-light-line p-2"
    >
      <b-row>
        <b-col cols="3" class="d-flex align-items-center">
          <div style="cursor: pointer" @click="prevPage">
            <feather-icon
              size="24"
              class="text-dark font-weight-bolder"
              icon="ArrowLeftIcon"
            />
            <span class="text-dark text-darken-4 fw-bold-600 size14 ml-1"
              >Kembali</span
            >
          </div>
        </b-col>
        <b-col
          cols="6"
          class="d-flex justify-content-center align-items-center"
        >
          <h1 class="text-dark h6 font-weight-bolder mb-0 text-center">
            {{ editId ? "Edit" : "Tambah" }} Produk Induk
          </h1>
        </b-col>
        <b-col cols="3" class="text-right">
          <b-button
            class="px-3"
            :disabled="disabled || disabledWarehouse"
            @click="editId ? updateItem() : createItem()"
          >
            Simpan
          </b-button>
        </b-col>
      </b-row>
    </header>
    <b-container class="mt-5 pt-5">
      <b-row class="mx-0 mt-5 justify-content-center">
        <!-- <b-col
          v-if="!$route.params.id"
          cols="12"
        >
          <b-row class="justify-content-center mb-1 mb-lg-5">
            <b-col cols="6">
              <div class="mb-2">
                <span class="text-dark text-darken-4 fw-bold-600 size14">Langkah {{ step }} dari 2</span>
              </div>
              <b-progress
                :value="progressValue"
                max="100"
                class="progress-bar-success"
                variant="success"
              />
            </b-col>
          </b-row>
        </b-col> -->
        <!-- <keep-alive> -->
        <b-col cols="12" md="8" lg="8">
          <DetailProduk
            :form-data="formPayload"
            :messages="messages"
            :image-url="image_url"
            :brand="brand"
            :options-barang="batch.items"
            :options-satuan="batch.uoms"
            :options-brand="batch.brands"
          />
          <PriceDiscount
            :form-data="formPayload"
            :messages="messages"
            :price-modal="priceModal"
          />
        </b-col>
        <b-col cols="12" md="8" lg="8">
          <InitialStock
            ref="initialStock"
            :form-data="formPayload"
            :arr-checked="arr_warehouse"
            :messages="messages"
            :warehouse-batch="batch.warehouses"
            @initialStocks="getPayloadInitialStock"
          />
          <Riwayat :uuid="$route.params.id" v-if="$route.params.id" />
        </b-col>
        <!-- </keep-alive> -->
      </b-row>
    </b-container>
    <!-- <ModalSave
      :type-modal="'induk'"
      :submit-data="createItem"
      :update-item="updateItem"
      :edit-id="editId"
      :is-loading="isLoading"
    /> -->
  </div>
</template>

<script>
import { BButton, BRow, BCol, BContainer, BProgress } from "bootstrap-vue";
import {
  checkPermission,
  successNotification,
  errorNotification,
} from "@/auth/utils";
import { mapActions } from "vuex";

import DetailProduk from "@/components/MasterProduct/Part/DetailProduk.vue";
import PriceDiscount from "@/components/MasterProduct/Part/PriceDiscount.vue";
import InitialStock from "@/components/MasterProduct/Part/InitialStocks.vue";
import Riwayat from "@/components/MasterProduct/Part/Riwayat.vue";
// import IncomingStock from "@/components/MasterProduct/Part/IncomingStock.vue";
import ModalSave from "@/components/MasterProduct/Modal/ModalSave.vue";

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BContainer,
    DetailProduk,
    PriceDiscount,
    BProgress,
    Riwayat,
    ModalSave,
    InitialStock,
    // IncomingStock,
  },
  setup() {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    };
  },
  data() {
    return {
      brand: {},
      options: [],
      isDelay: true,
      messages: Object,
      isLoading: false,
      initial_stocks: {
        warehouse_uuid: "",
        note: "",
        stock: 0,
      },
      formPayload: {
        product_type: "parent",
        image: "",
        brand_uuid: "",
        item_uuid: "",
        specification: "",
        uom_uuid: "",
        alias_name: "",
        sku_code: 0,
        standard_price: 0,
        minimum_price: 0,
        avg_purchase_price: 0,
        discounts: [],
        discount_start_date: "",
        discount_end_date: "",
        initial_stocks: [
          {
            warehouse_uuid: "",
            note: "",
            stock: 0,
          },
        ],
      },
      image_url: null,
      editId: this.$route.params.id ? this.$route.params.id : null,
      step: this.$store.state.formWizard.step,
      progressValue: 50,
      disabledWarehouse: true,
      disabled: true,
      arr_warehouse: [],
      batch: Object,
      priceModal: 0,
    };
  },
  watch: {
    // eslint-disable-next-line func-names
    "$route.params.id": function (value) {
      this.editId = value;
    },
    "$store.state.formWizard.step": function (value) {
      this.step = value;
      if (this.$route.params.id) {
        this.getDetail();
      }
      if (!this.$route.params.id) {
        this.getPayloadPrice();
        this.getPayloadProduct();
        // this.getPayloadInitialStock()
      }
      this.stepForm();
    },
    "$store.state.parentProduct.product": {
      handler(value) {
        this.disabledStep1(value);
      },
      deep: true,
    },
  },
  async mounted() {
    await this.getDetail();
    await this.getBatchRequest();
  },
  methods: {
    ...mapActions("parentProduct", ["postData"]),
    async getBatchRequest() {
      const request = [
        {
          url: "/api/warehouse?per_page=50",
          type: "GET",
          request_id: "warehouses",
        },
        {
          url: "/api/item",
          type: "GET",
          request_id: "items",
        },
        {
          url: "/api/uom",
          type: "GET",
          request_id: "uoms",
        },
        {
          url: "/api/brand",
          type: "GET",
          request_id: "brands",
        },
      ];
      const requestId = [
        {
          url: "/api/warehouse?per_page=50",
          type: "GET",
          request_id: "warehouses",
        },
        {
          url: "/api/item",
          type: "GET",
          request_id: "items",
        },
        {
          url: "/api/uom",
          type: "GET",
          request_id: "uoms",
        },
        {
          url: "/api/brand",
          type: "GET",
          request_id: "brands",
        },
        // {
        //   url: `/api/item/${this.formPayload.item_uuid}`,
        //   type: 'GET',
        //   request_id: 'detail_item',
        // },
        // {
        //   url: `/api/uom/${this.formPayload.uom_uuid}`,
        //   type: 'GET',
        //   request_id: 'detail_uom',
        // },
        // {
        //   url: `/api/brand/${this.formPayload.brand_uuid}`,
        //   type: 'GET',
        //   request_id: 'detail_brand',
        // },
        // {
        //   url: `/api/product/${this.$route.params.id}/harga-modal`,
        //   type: 'GET',
        //   request_id: 'harga_modal',
        // },
      ];
      try {
        const response = await this.$store.dispatch("batch/getData", {
          requests: this.$route.params.id
            ? JSON.stringify(requestId)
            : JSON.stringify(request),
        });
        this.batch = response.data.data;
        this.priceModal = response.data.data?.harga_modal?.modal;
      } catch (error) {
        console.log(error);
      }
    },
    disabledStep1(value) {
      const isUpdate = this.$route.params.id !== undefined;

      let form = {};
      if (value) {
        form = {
          uom_uuid: value.uom_uuid.label || "",
          alias_name: value.alias_name,
        };
        if (!isUpdate) {
          form.min_low_stock = value.min_low_stock || "";
        }
        this.disabled = Object.values(form).some((e) => e === "");
      }
    },
    nextSubmit() {
      if (!this.$route.params.id) {
        this.$bvModal.show("modal-save");
      }
      if (this.$route.params.id) {
        this.$bvModal.show("modal-save");
      }
    },
    prevPage() {
      switch (this.step) {
        case 1:
          this.brand = {};
          this.$router.go(-1);
          break;
        case 2:
          this.brand = {};
          this.$store.commit("formWizard/setStep", 1);
          break;
        default:
          break;
      }
    },
    stepForm() {
      switch (this.step) {
        case 1:
          this.progressValue = 50;
          break;
        case 2:
          this.progressValue = 100;
          break;
        default:
          break;
      }
    },
    async getDetail() {
      if (this.$route.params.id) {
        await this.$store
          .dispatch("parentProduct/getData", {
            params: "",
            uuid: this.$route.params.id,
          })
          .then((result) => {
            this.formPayload = {
              product_type: "parent",
              uom_uuid: {
                label: result.data.data.uom?.uuid || "",
                name: result.data.data.uom?.name || "",
              },
              alias_name: result.data.data.alias_name,
              related_product: result.data.data.related_product?.name,
              standard_price: result.data.data.standard_price,
              minimum_price: result.data.data.minimum_price,
              avg_purchase_price: result.data.data.avg_purchase_price,
              discounts: result.data.data.discounts,
              discount_start_date: result.data.data.discount_start_date,
              discount_end_date: result.data.data.discount_end_date,
              buying_price: result.data.data.buying_price,
              latest_order_price: result.data.data.latest_order_price,
              initial_stocks: result.data.data.stock_in_warehouse,
              show_on_low_stock: result.data.data.show_on_low_stock,
              min_low_stock: result.data.data.min_low_stock,
            };
            this.arr_warehouse = result.data.data.stock_in_warehouse.map(
              (e) => e.uuid
            );
            this.image_url = result.data.data.image_url;
            this.brand = result.data.data.brand || "";
          })
          .catch((err) => {
            // eslint-disable-next-line no-console
            console.log(err);
          });
      }
    },
    getPayloadPrice(value) {
      this.formPayload = value;
    },
    getPayloadProduct(value) {
      this.formPayload = value;
    },
    getPayloadInitialStock(value) {
      this.disabledWarehouse = value.every((e) => !e.warehouse_uuid);
    },
    clearItem() {
      localStorage.removeItem("uom_uuid");
      this.$store.commit("parentProduct/setEditId", null);
      this.$store.commit("parentProduct/setImageUrl", null);
      this.$store.commit(
        "parentProduct/setFormPayload",
        (this.formPayload = {
          product_type: "",
          image: "",
          item_uuid: "",
          specification: "",
          uom_uuid: "",
          alias_name: "",
          sku_code: 0,
          standard_price: 0,
          minimum_price: 0,
          discounts: [],
          discount_start_date: "",
          discount_end_date: "",
          initial_stocks: [],
        })
      );
    },
    createItem() {
      this.$swal({
        title: "Konfirmasi",
        text: "Apa Anda yakin ingin membuat produk induk baru?",
        icon: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          localStorage.setItem(
            "uom_uuid",
            JSON.stringify(this.$store.state.parentProduct.product.uom_uuid)
          );
          const form = this.preparePayload();

          this.isLoading = true;
          this.$store
            .dispatch("parentProduct/postData", {
              query: "",
              payload: form,
            })
            .then(() => {
              successNotification(this, "Success", "Produk berhasil di buat");
              this.clearItem();
              this.isLoading = false;
              this.$router.push({
                name: "master-product.index",
                query: { page: this.$route.query.page || 1 },
              });
              this.$store.commit("formWizard/setStep", 1);
            })
            .catch((error) => {
              this.formPayload.uom_uuid = JSON.parse(
                localStorage.getItem("uom_uuid")
              );
              this.isLoading = false;
              if (error.response.data.meta.messages) {
                this.messages = error.response.data.meta.messages;
                // errorNotification(this, 'Oops!', error.response.data.meta.messages)
              }
            });
        }
      });
    },
    updateItem() {
      this.$swal({
        title: "Konfirmasi",
        text: "Apa Anda yakin ingin mengubah data produk induk ini?",
        icon: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          localStorage.setItem(
            "uom_uuid",
            JSON.stringify(this.$store.state.parentProduct.product.uom_uuid)
          );
          this.formPayload.product_type = "parent";
          if (this.$route.params.id) {
            delete this.formPayload.initial_stocks;
          }
          const form = this.preparePayload();
          this.isLoading = true;
          form.append("_method", "PATCH");

          this.$store
            .dispatch("parentProduct/postData", {
              query: `/${this.editId}`,
              payload: form,
            })
            .then(() => {
              this.$store.commit("formWizard/setStep", 1);
              successNotification(this, "Success", "Produk berhasil di ubah!");
              this.clearItem();
              this.isLoading = false;
              this.$router.push({
                name: "master-product.index",
                query: { page: this.$route.query.page || 1 },
              });
              // this.$bvModal.hide('modal-add-product-parent')
              this.$store.commit("formWizard/setStep", 1);
            })
            .catch((error) => {
              this.formPayload.uom_uuid = JSON.parse(
                localStorage.getItem("uom_uuid")
              );
              this.isLoading = false;
              if (error.response.data.meta.messages) {
                this.messages = error.response.data.meta.messages;
                // errorNotification(this, 'Oops!', error.response.data.meta.messages)
              }
            });
        }
      });
    },
    preparePayload() {
      const isUpdate = this.$route.params.id !== undefined;

      this.formPayload = {
        product_type: "parent",
        image: this.$store.state.parentProduct.product.image,
        product_uuid: this.$store.state.parentProduct.product.product_uuid,
        // brand_uuid: this.$store.state.parentProduct.product.brand_uuid.label || this.$store.state.parentProduct.product.brand_uuid,
        // item_uuid: this.$store.state.parentProduct.product.item_uuid.label || this.$store.state.parentProduct.product.item_uuid,
        // specification: this.$store.state.parentProduct.product.specification,
        uom_uuid:
          this.$store.state.parentProduct.product.uom_uuid.label ||
          this.$store.state.parentProduct.product.uom_uuid ||
          "",
        alias_name: this.$store.state.parentProduct.product.alias_name,
        // sku_code: this.$store.state.parentProduct.product.sku_code,
        standard_price:
          this.$store.state.parentProduct.discounts.standard_price,
        minimum_price: this.$store.state.parentProduct.discounts.minimum_price,
        avg_purchase_price:
          this.$store.state.parentProduct.discounts.avg_purchase_price,
        discounts: this.$store.state.parentProduct.discounts.discounts,
        discount_start_date:
          this.$store.state.parentProduct.discounts.discount_start_date,
        discount_end_date:
          this.$store.state.parentProduct.discounts.discount_end_date,
        buying_price: this.$store.state.parentProduct.discounts.buying_price,
        initial_stocks: this.$store.state.parentProduct.initial_stocks,
        show_on_low_stock: isUpdate
          ? this.$store.state.parentProduct.product.show_on_low_stock
          : 1,
        min_low_stock: this.$store.state.parentProduct.product.min_low_stock,
      };
      // this.formPayload.initial_stocks = this.$store.state.parentProduct.initial_stocks
      // this.formPayload.product_type = 'parent'
      const form = new FormData();
      // eslint-disable-next-line no-restricted-syntax
      for (const key in this.formPayload) {
        // eslint-disable-next-line no-prototype-builtins
        if (
          this.formPayload.hasOwnProperty(key) &&
          this.formPayload[key] != null
        ) {
          if (key !== "discounts" && key !== "initial_stocks") {
            form.append(key, this.formPayload[key]);
          }
        }
      }
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < this.formPayload.discounts.length; index++) {
        const element = this.formPayload.discounts[index];
        // eslint-disable-next-line no-restricted-syntax
        for (const key in element) {
          if (Object.hasOwnProperty.call(element, key)) {
            // eslint-disable-next-line no-use-before-define
            const items = element[key];
            form.append(`discounts[${index}][${key}]`, items);
          }
        }
      }
      if (this.formPayload.initial_stocks) {
        // eslint-disable-next-line no-plusplus
        for (
          let index = 0;
          index < this.formPayload.initial_stocks[0].length;
          index++
        ) {
          const element = this.formPayload.initial_stocks[0][index];
          if (
            element.warehouse_uuid !== "" &&
            element.warehouse_uuid !== false &&
            element.warehouse_uuid !== true &&
            element.warehouse_uuid !== undefined
          ) {
            // eslint-disable-next-line no-restricted-syntax
            for (const key in element) {
              if (Object.hasOwnProperty.call(element, key)) {
                // eslint-disable-next-line no-use-before-define
                const items = element[key];
                if (key !== "stock" && key !== "note") {
                  form.append(`initial_stocks[${index}][${key}]`, items);
                }
              }
            }
          }
        }
      }
      return form;
    },
    directPage() {
      this.clearItem();
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/variables/_variables.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";

body {
  background-color: #fff !important;
}

.bg-dark {
  background: $dark !important;
}

.custom__input {
  &.error-validation {
    background: #feefef;
    border: 1px solid #f48989;

    .input-group-text {
      background-color: #feefef !important;
      border: 1px solid #f48989 !important;
    }
  }
}

.error-validation {
  .input-group-text {
    background-color: #feefef !important;
    border: 1px solid #feefef !important;
  }
}

.v-select {
  &.error-validation {
    .vs__dropdown-toggle {
      background: #feefef !important;
      border: 1px solid #f48989 !important;
    }
  }
}

.progress-bar-success {
  background-color: #eff1f5;
  .progress-bar {
    background: linear-gradient(
      269.89deg,
      #6cdfd4 0%,
      #45b6ab 98.81%
    ) !important;
    box-shadow: 0px 6px 8px rgba(44, 129, 120, 0.06);
    border-radius: 20px;
  }
}

label {
  font-size: 14px;
  color: $dark;
  font-weight: 600;
}

.vs__open-indicator {
  fill: rgba(60, 60, 60, 0.5);
}

.custom__input {
  height: 52px;
  background: #eff1f5;
  border: 1px solid #e4e5ec;
  border-radius: 8px;
}

.vs__dropdown-toggle {
  height: 52px;
  background-color: #eff1f5;
  border: 1px solid #e4e5ec;
  border-radius: 8px;
}
</style>
